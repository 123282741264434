<template>
  <div id="withdraw-methods">
    <van-nav-bar title="提现方式" right-text="" left-arrow @click-left="goLastPage" @click-right="goSubPage('withdraw-list')" scrollable />
    <van-notice-bar mode="closeable">提现账户不能随意修改，如需修改提现账户,请在添加提现账户7天后进行修改</van-notice-bar>
    <div class="methods-list">
      <div class="methods-item" v-for="(item,index) in with_draw_list" :key="index">
        <svg class="icon icon-rerset-withdraw-methods" aria-hidden="true">
          <use :xlink:href="item.icon"></use>
        </svg>
        <p class="type-name">{{item.name}}</p>
        <div></div>
      </div>
    </div>
    <div class="add-methods  flexCenter" @click="add_withdraw_show = true">
      添加提现账户
      <svg class="icon inon-reset-add-withdraw " aria-hidden="true">
        <use xlink:href="#icon-add"></use>
      </svg>
    </div>
    <p class="tip">
      注意：提现账户不能随意修改，如需修改提现账户,请在添加提现账户7天后进行修改
    </p>
    <van-popup v-model="add_withdraw_show" round position="bottom" :style="{ height: '30%',padding:'50px 0', positio:'absulate' }" closeable>
      <p class="add_title ">请选择提现方式</p>
      <van-cell :value="`添加${item.name}账户`" v-for="item in with_draw_list" :key="item.id" @click="selectedMethods(item)" clickable />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      add_withdraw_show: true,
      with_draw_list: [
        {
          id: 0,
          name: "银行卡",
          icon: "#icon--",
          route_name: "add-withdraw-methods-bank-card",
        },
        {
          id: 1,
          name: "微信",
          icon: "#icon-weixin1",
          route_name: "add-withdraw-methods-WeChat",
        },
        {
          id: 2,
          name: "支付宝",
          icon: "#icon-zhifubao1",
          route_name: "add-withdraw-methods-Alipay",
        },
      ],
      add_selected_methods: "",
    };
  },
  created() {
    this.getBindMethods();
    console.log(1)
  },
  methods: {
    selectedMethods(item) {
      console.log(item);
      this.goSubPage(item.route_name);
    },
  
  },
};
</script>

<style lang="less">
#withdraw-methods {
  .icon-rerset-withdraw-methods {
    width: 4rem;
    height: 4rem;
  }
  .inon-reset-add-withdraw {
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
  }
  .methods-item {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    .type-name {
      font-size: 1.4rem;
      margin-left: 2rem;
    }
  }

  .add-methods {
    width: 90%;
    background: #fff;
    padding: 1rem 0;
    margin: 5rem auto;
    border-radius: 200px;
    font-size: 1.4rem;
  }
  .tip {
    margin: 4rem;
    font-size: 1.3rem;
  }
  .add_title {
    font-size: 1.4rem;
    font-weight: 600;
    position: relative;
    top: -2.3rem;
    text-align: center;
  }
}
</style>